<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-15" style="margin-top: 0px;">
            <a-col :span="24" :md="16" :lg="16" :xl="16">
                <h5 class="mt-15">Categories</h5>
            </a-col>

			<a-col :span="24" :md="8" :lg="8" :xl="8" class="mt-10 text-right">
                <a-button
                    type="primary"
                    class="ml-15"
                    size="small"
                    @click="onCreateLabel"
                    style="border-radius: 20px;">
                    Add Category
                    <a-icon type="plus" theme="outlined" />
                </a-button>
			</a-col>
		</a-row>

		<div class="mb-20 mt-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
					<a-card>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="categories"
									:loading="loadingData" 
									:pagination="true" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
                                        </template>

                                        <template slot="name" slot-scope="row"> 
                                            {{ formatName(row.name) }}
                                        </template>

                                        <template slot="created" slot-scope="row">
                                            <p class="m-0">
                                                {{ $Moment(row.createdAt).format('DD MMM YYYY')}}
                                            </p>
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="onEditLabel(row)"
                                                style="border-radius: 20px;">
                                                <a-icon type="form" theme="outlined" />
                                            </a-button>

                                            <a-button
                                                id="deleteBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="onDeleteLabel(row.uuid)"
                                                style="border-radius: 20px;">
                                                <a-icon type="delete" theme="outlined" />
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                        
                    </a-card>
				</a-col>

			</a-row>

		</div>

		<!-- <div v-if="loadingData">

			<a-row type="flex" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="24" :lg="24" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div> -->


        <!-- FORM MODAL -->
		<a-modal v-model="category.showModal" :footer="null" :width="480">
			<h4 class="text-center">{{  category.isCreate ? 'Add Categories' : 'Edit Categories'}}</h4>
			<a-form-model
				:form="form"
				class="login-form"
				@submit.prevent="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Name" :colon="false">
					<a-input 
                        placeholder="Name" 
						v-model="category.name"/>
				</a-form-item>
                

				<a-form-item>
					<a-button type="primary" :loading="category.loading" block html-type="submit" class="login-form-button text-white">
						{{ category.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>


	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';


    const columns = [
        {
            title: 'S/N',
            scopedSlots: { customRender: 's_no' },
            width: 50
        },
        {
            title: 'Categories',
            scopedSlots: { customRender: 'name' },
            width: 200
        },
        {
            title: 'Created',
            scopedSlots: { customRender: 'created' },
            width: 100
        },
        {
            title: 'Action',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                form: this.$form.createForm(this, { name: 'category_form' }),
				categories: [],
                classEarnings: 0,
                columns,
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'all',
                    perPage: 13,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

                userDetails: {},

                category: {
                    uuid: null,
                    name: null,
                    thumbnail: null,
                    creator: null,
                    isCreate: true,
                    showModal: false,
                    loading: false,
                }
      		}
		},
		watch: {
            
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getClassCategories();
                },
            },
            deep: true,
        },
		async mounted () {

			let breadcrumbs

            let route = await localStorage.getItem('previous_route');
            
            if(route != null && route.includes('class')) {
                breadcrumbs = [
                    { title: 'Classes', url: route, isActive: false },
                    { title: 'Categories', url: '', isActive: true }
                ];
            }

            else if(route != null && route.includes('quiz')) {
                breadcrumbs = [
                    { title: 'Quizzes', url: route, isActive: false },
                    { title: 'Categories', url: '', isActive: true }
                ];
            }
            
            else if(route != null && route.includes('note')) {
                breadcrumbs = [
                    { title: 'Quizzes', url: route, isActive: false },
                    { title: 'Categories', url: '', isActive: true }
                ];
            }
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.getCategories();
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },


            getClassCategories() {

            },


            onCreateLabel() {
                this.category.uuid = null;
                this.category.name = null;
                this.category.loading = false;
                this.category.showModal = true;
            },

            onEditLabel(item) {
                this.category.uuid = item.uuid;
                this.category.name = item.name;
                this.category.isCreate = false;
                this.category.loading = false;
                this.category.showModal = true;
            },


            async getCategories() {

				this.loadingData = true;

                let userInfo = await localStorage.getItem("user_details")

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {}

                this.userDetails = userDetails;

                const { currentPage, perPage, search } = this.pagination;
                  
                let url = `${this.$BACKEND_URL}/categories?creator=${userDetails.uuid}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.categories = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


        
            handleSubmit() {

				this.category.creator = this.userDetails.uuid;

                if(this.category.isCreate) {

                    this.category.loading = true;

                    let url = `${this.$BACKEND_URL}/categories`;

                    this.category.uuid = undefined;

                    this.$AXIOS.post(url, this.category).then((response) => {
                        
                        this.category.uuid = null;
                        this.category.name = null;
                        this.category.isCreate = true;
                        this.category.showModal = false;
                        this.category.loading = false;

                        this.notify('Category has been created successfully', 'success')

                        this.getCategories()

                    }).catch(async(error) => {
                        
                        this.category.loading = false;

                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
						    await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    });

                }else{
                    
                    this.category.loading = true;

                    let url = `${this.$BACKEND_URL}/categories/${this.category.uuid}`;

                    this.category.uuid = undefined;

                    this.$AXIOS.patch(url, this.category).then((response) => {
                        
                        this.category.uuid = null;
                        this.category.name = null;
                        this.category.isCreate = true;
                        this.category.showModal = false;
                        this.category.loading = false;

                        this.notify('Category has been updated successfully', 'success')

                        this.getCategories();

                    }).catch(async(error) => {
                        console.log(error)

                        this.category.loading = false;
                        
                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
						    await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    });
                }
			},


            async onDeleteLabel(uuid) {

                this.loadingData = true;

				let url = `${this.$BACKEND_URL}/categories/${uuid}`

				this.$AXIOS.delete(url).then(async(response) => {

                    this.loadingData = false;
					
					this.notify('Category has been deleted successfully', 'success')
					
					this.getCategories()

				}).catch(async(error) => {
					
					this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

            
            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },


		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 5px;
}

a:hover {
    color: inherit !important;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}
</style>